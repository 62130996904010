import React from "react"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
import {Button, Box } from "@mui/material"
import { contrastColour } from "../utils/contrastColour"

export const ButtonFormat = props => {
  const { node, sx, endIcon, variant, color } = props

  let definedInternal = node?.link?.internal?.slug?.current
  const definedExternal = node?.link?.external

  if(node?.link?.internal?._type === "post"){

    definedInternal = `blog/${node?.link?.internal?.category?.slug?.current}/${definedInternal}`
  }
  if(node?.link?.internal?._type === "caseStudy" ){
    definedInternal = `/case-studies/${definedInternal}`
  }
  if(node?.link?.internal?._type === "teamMembers" ){
    definedInternal = `/team-members/${definedInternal}`
  }

  return (
    <Box sx={sx}>
      {node?.link?.internal ? (
        <GatsbyButton
          size="small"
          //color={!node?.color && color}
          variant={variant}
          to={`/${definedInternal}`} 
          endIcon={endIcon}
          sx={{
            border: variant !== 'text' && `1px solid ${node?.color?.value}`,
            backgroundColor: variant !== 'outlined' && variant !== 'text' && node?.color?.value,
            color: variant === 'outlined' ? node?.color?.value : contrastColour(node?.color).contrastText.hex,
          }}
        >
          {node?.text}
        </GatsbyButton>
      ) : definedExternal ? (
        <Button 
        size="small"
        //color={!node?.color && color}
        variant={variant} 
        href={definedExternal} 
        rel="noopener"
        target="_blank"
        endIcon={endIcon}
        sx={{
          border: variant !== 'text' && `1px solid ${node?.color?.value}`,
          backgroundColor: variant !== 'outlined' && variant !== 'text' && node?.color?.value,
          color:  variant === 'outlined' ? node?.color?.value : contrastColour(node?.color).contrastText.hex,
        }}>
          {node.text}
        </Button>
      ) : null}
    </Box>
  )
}
